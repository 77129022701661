.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-color: #0077b6;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 10;
}
h1 {
  text-align: center;
}
.buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}
.payment-language-button {
  display: flex; /* Use flexbox to center content */
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
  cursor: pointer;
  border: none;
  background-color: #004e96;
  color: white;
  padding: 0.7rem 1.5rem;
  border-radius: 25px;
  font: inherit;
  font-weight: 600;
  font-size: 0.8rem;
  gap: 5px;
  max-height: 2.688rem;
}
.payment-language-button:hover {
  background-color: #00245e;
}
@media (max-width: 920px) {
  h1 {
    font-family: inherit;
    font-weight: 600;
    font-size: 1.3rem;
    margin-top: -0.5rem;
  }
  .header {
    height: 7.5rem;
    flex-direction: column;
    justify-content: center;
    gap: 0.1rem;
  }
  .buttons-container {
    gap: 5px;
    width: 100%;
  }
  .payment-language-button {
    padding: 0.7rem 0.7rem;
  }
}
@media (max-width: 400px) {
  .payment-language-button {
    font-size: 0.7rem;
  }
  .buttons-container {
    gap: 5px;
    width: 100%;
  }
}
