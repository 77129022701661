.footer {
  width: 100%;
  min-height: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  padding: 20px;
}

.policy a {
  color: rgb(51, 51, 51);
  font-size: larger;
}
.policy a:hover {
  color: rgba(22, 102, 194, 0.993);
}
.footer p {
  font-size: 0.8rem;
  font-weight: bold;
}
.imagesContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.visaImage {
  max-width: 4rem;
  height: 2rem;
}
.masterImage {
  max-width: 9rem;
  height: 2.2rem;
}
@media (max-width: 500px) {
}
