.actions {
  text-align: center;
  padding-top: 15px;
}
.payment-title {
  margin-top: 10px;
  margin-bottom: 10px;
}
.payment-title p {
  font-size: 2rem;
  text-align: center;
  margin: 0;
  margin-top: -15px;
  font-weight: bold;
}
.actions button {
  font: inherit;
  cursor: pointer;
  font-size: 0.9rem;
  background-color: transparent;
  border: 1px solid #0077b6;
  padding: 0.4rem 1.4rem;
  border-radius: 25px;
  margin-left: 1rem;
}
.invoicement {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.invoicement p {
  border: 1px solid black;
  padding: 10px;
  margin: 0px;
  font-size: 1.1rem;
}
.invoicement h2 {
  text-align: center;
}
.actions button:hover,
.actions button:active {
  background-color: #004c75;
  border-color: #004c75;
  color: white;
}

.actions .button--alt {
  color: #0077b6;
}

.actions .button {
  background-color: #0077b6;
  color: white;
}
.note {
  margin-top: 10px;
  font-size: 0.9rem;
}
.paymentMethodsForm {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  min-height: 20rem;
}

.paymentFormTextPhoto {
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
  justify-content: space-between;
}
.arabbank {
  margin: 0 1.5rem 0 1.5rem;

  max-width: 5rem;
}

.bopbank {
  max-width: 5rem;
}

@media (max-width: 768px) {
  .paymentMethodsForm {
    flex-direction: column;
    gap: 50px;
  }
  .paymentFormTextPhoto {
    flex-direction: column-reverse;
    align-items: center;
  }
}
