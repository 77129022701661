.button {
  cursor: pointer;
  font: inherit;
  border: none;
  background-color: #004e96;
  color: white;
  padding: 0.75rem 2rem;
  display: flex;
  justify-content: space-around;
  gap: 0.5rem;
  align-items: center;
  border-radius: 25px;
  font-weight: bold;
}
.title {
  font-size: 0.8rem;
}
.button:hover,
.button:active {
  background-color: #00245e;
}

.icon {
  width: 1.35rem;
  height: 1.35rem;
}

.badge {
  background-color: #0077b6;
  padding: 0.25rem 1rem;
  border-radius: 25px;
  font-weight: bold;
}

.button:hover .badge,
.button:active .badge {
  background-color: #0077b6;
}

.bump {
  animation: bump 300ms ease-out;
}

@keyframes bump {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
@media (max-width: 768px) {
  .button {
    padding: 0.45rem 1rem;
  }
}

@media (max-width: 400px) {
  .button {
    font-size: 1rem;
    padding: 0.6rem 0.6rem;
    gap: 0.1rem;
  }
}
