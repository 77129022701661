@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400&family=Tajawal:wght@200;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100;200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Noto+Sans+Arabic:wght@100;200;300;400;500;600&display=swap");
* {
  box-sizing: border-box;
}

html {
  font-family: "Montserrat", "Noto Sans Arabic";
  /* font-family: "BebasNeue", "Noto Sans Arabic"; */
}

body {
  margin: 0;
  background-color: #0077b6;
  background-image: linear-gradient(0deg, #d9afd9 0%, #97d9e1 100%);
}
