.sectionTitle {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.offers {
  width: 95%;
  margin: 2rem auto;
  animation: offers-appear 1s ease-out forwards;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 5px;
}

.OffersLoading {
  text-align: center;
  color: white;
  margin: 10rem auto;
}

.OffersError {
  text-align: center;
  color: red;
  margin: 10rem auto;
}

.offers ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@keyframes offers-appear {
  from {
    opacity: 0;
    transform: translateY(3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (max-width: 768px) {
  .sectionTitle {
    margin-top: 2rem;
    margin-bottom: 0;
  }
}
