.form {
  margin: 1rem 0;
  min-height: 20rem;

  overflow: auto;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  font-weight: bold;
  margin-bottom: 0.25rem;
  display: block;
  color: rgb(56, 56, 56);
  margin-bottom: 5px;
}

.control input {
  font: inherit;
  width: 300px; /* Set the desired width */
  padding: 10px; /* Add some padding for better appearance */
  border: 2px solid rgba(56, 56, 56, 0.603); /* Add a border with a light gray color */
  border-radius: 5px; /* Round the corners of the input */
  font-size: 16px; /* Set the font size */
  color: #333; /* Set the text color */
  transition: border-color 0.6s ease-out; /* Add transition properties */
}

.actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.actions button {
  font: inherit;
  color: #0077b6;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 0.9rem;
  padding: 0.4rem 1.4rem;
  border-radius: 25px;
}

.actions button:hover,
.actions button:active {
  background-color: #90d0f3;
}

.actions .submit {
  border: 1px solid #0077b6;
  background-color: #0077b6;
  color: white;
}

.actions .submit:hover,
.actions .submit:active {
  background-color: #015583;
}

.invalid label {
  color: #700000;
}

.invalid input {
  border-color: #700000;
  background-color: #424242;
}

.actions button[disabled] {
  background-color: #aaaaaa; /* Change the background color when disabled */
  color: #7c7c7c; /* Change the text color when disabled */
  cursor: not-allowed; /* Change the cursor style when disabled */
  border: none;
}
.gap {
  min-height: 20px;
}
